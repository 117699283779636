// Them
import React, {
  useCallback, useState, useEffect, useRef,
} from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

// Us
import { useClientHubLiveStats } from '@components/data/clientHubLiveStats';

export default function LiveStats() {
  const [logs, setLogs] = useState(new Array<string>());
  const [autoScroll, setAutoScroll] = useState(true);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const reportLiveStats = useCallback((msg: string) => {
    setLogs((oldLogs) => [...oldLogs, msg]);
  }, []);

  useClientHubLiveStats(reportLiveStats);

  useEffect(() => {
    if (textareaRef.current && autoScroll) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [logs, autoScroll]);

  return (
    <>
      <FormGroup>
        <FormControlLabel control={<Switch defaultChecked onChange={() => setAutoScroll(!autoScroll)} />} label={`Auto-scroll ${(autoScroll ? 'enabled' : 'disabled')}`} />
      </FormGroup>
      <textarea ref={textareaRef} style={{ width: '95%', height: '90vh' }} value={logs.join('')} readOnly />
    </>
  );
}
