// Them
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { makeStyles, useTheme } from '@mui/styles';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TuneIcon from '@mui/icons-material/Tune';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Us
import Workspace from '@models/Workspace';
import { useUserProfile } from '@store/userProfileSlice';
import { setActiveWorkspaceAsync, useWorkspaces } from '@store/workspacesSlice';
import { useProduct } from '@store/productSlice';
import { reactPath } from '@components/utilities/Paths';
import { navContent, navWorkspace } from '@components/utilities/Navigation';
import { CustomTheme, useResponsiveToolbarHeight } from '@components/layout/Theme';
import WorkspaceButton from '@models/WorkspaceButton';
import { logoff } from '@components/data/NetworkMonitor';
import IconWidget, { showIcon } from './Icon';
import { handleWorkspaceButton, filterButton } from './implementation/workspaceHelpers';
import Message from './Message';

const useStyles = makeStyles((customTheme: CustomTheme) => ({
  root: {
    display: 'flex',
  },
  appBarChild: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    alignItems: 'center',
  },
  appBarLogo: {
    margin: '.5rem',
    height: '44px',
  },
  appBarMessageContainer: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    minWidth: 0,
  },
  appBarMessage: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

const StyledUl = styled('ul')(({ theme }: { theme: CustomTheme }) => ({
  margin: theme.spacing(0, 0, 1, 1.5),
  listStyleType: 'none',
  paddingLeft: 0,
}));

const StyledLi = styled('li')(({ theme }: { theme: CustomTheme }) => ({
  margin: theme.spacing(0.5, 1, 0, 0),
}));

const StyledLink = styled(Link)(({ theme }: { theme: CustomTheme }) => ({
  textDecoration: 'none',
  textTransform: 'none',
  color: 'inherit',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: '0.25rem',
}));

const StyledButton = styled(Button)(({ theme }: { theme: CustomTheme }) => ({
  textDecoration: 'none',
  textTransform: 'none',
  padding: 0,
  color: 'inherit',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: '0.25rem',
}));

export default function LayoutMobile() {
  const workspaces = useWorkspaces();
  const workspace = workspaces?.find((ws) => ws.isActive);
  const dispatch = useDispatch();
  const userProfile = useUserProfile();
  const history = useHistory();
  const theme = useTheme() as CustomTheme;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const toolbarHeight = useResponsiveToolbarHeight();
  const product = useProduct();
  const toggleMenu = () => {
    setOpen(!open);
  };

  const handleLogoff = () => {
    setOpen(false);
    logoff();
  };

  const handleNavigate = (url: string, title: string) => {
    setOpen(false);
    navWorkspace(history, url, title);
  };

  const handleClick = (button: WorkspaceButton) => {
    setOpen(false);
    handleWorkspaceButton(button, workspace, history);
  };

  const handleSwitchWorkspace = async (active: Workspace) => {
    setOpen(false);
    setActiveWorkspaceAsync(dispatch, active.id);
    navContent(history, active.url);
  };

  const getUserName = (): string => {
    if (userProfile.userName && userProfile.userId) {
      return `${userProfile.userName} (${userProfile.userId})`;
    } if (userProfile.userName) {
      return userProfile.userId;
    } if (!userProfile.userName && userProfile.userId) {
      return userProfile.userId;
    }
    return '(Not available)';
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: product.altBannerColor }}
      >
        <div className={classes.appBarChild}>
          <IconButton
            arial-label="open drawer"
            onClick={toggleMenu}
            sx={{
              width: 'min-content',
              color: theme.palette.common.white,
              marginLeft: theme.spacing(0.5),
              '&:hover': {
                backgroundColor: theme.palette.action.hoverInverse,
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={`${reactPath}/bp-logix-logo.svg`}
            alt="BP Logix"
            className={classes.appBarLogo}
          />
          <Message
            containerClassName={classes.appBarMessageContainer}
            textClassName={classes.appBarMessage}
            MsgText={product.envMsgText || ''}
            tooltipColor={product.altBannerColor}
          />
        </div>
        {open && (
          <Box sx={{ overflowY: 'auto', height: `calc(100vh - ${toolbarHeight}px)` }}>
            <StyledUl sx={{ margin: theme.spacing(1, 1, 1, 1) }}>
              {userProfile?.isAdmin && (
                <StyledLi>
                  <StyledLink
                    to="/home"
                    onClick={toggleMenu}
                  >
                    Home
                  </StyledLink>
                </StyledLi>
              )}
              {workspace && (
                <StyledLi key={workspace.id}>
                  <StyledButton onClick={() => handleSwitchWorkspace(workspace)}>
                    {`Active Workspace: ${workspace.name}`}
                  </StyledButton>
                  {workspace.children && (
                    <StyledUl>
                      {workspace.children && workspace.children
                        .filter((child) => filterButton(child))
                        .sort((lhs, rhs) => lhs.order - rhs.order)
                        .map((child) => (
                          <StyledLi key={child.id}>
                            <StyledButton
                              key={child.id}
                              onClick={() => handleClick(child)}
                            >
                              {(child.icon && showIcon(child.displayOptions)) ? <IconWidget icon={child.icon} color="white" /> : undefined}
                              <Typography variant="inherit" noWrap sx={{ padding: 0, textDecoration: 'none' }}>
                                {child.name || child.tooltip}
                              </Typography>
                            </StyledButton>
                          </StyledLi>
                        ))}
                    </StyledUl>
                  )}
                </StyledLi>
              )}
              {workspaces && (
                <StyledLi>
                  Switch Workspace
                  <StyledUl>
                    {workspaces
                      .filter((ws) => !ws.isActive)
                      .sort((lhs, rhs) => lhs.order - rhs.order)
                      .map((ws) => (
                        <StyledLi key={ws.id}>
                          <StyledButton onClick={() => handleSwitchWorkspace(ws)}>
                            {(ws.icon && showIcon(ws.displayOptions)) ? <IconWidget icon={ws.icon} color="white" /> : undefined}
                            <Typography variant="inherit" noWrap>
                              {ws.name || ws.tooltip}
                            </Typography>
                          </StyledButton>
                        </StyledLi>
                      ))}
                  </StyledUl>
                </StyledLi>
              )}
              <StyledLi>
                Settings
                <StyledUl>
                  {[
                    { title: 'Configuration', url: '/admin/profiles.aspx', icon: <TuneIcon fontSize="small" /> },
                    { title: 'User Administration', url: '/admin/users.aspx', icon: <ManageAccountsIcon fontSize="small" /> },
                    { title: 'Installation Settings', url: '/admin/properties.aspx?npage=asp', icon: <InstallDesktopIcon fontSize="small" /> },
                    { title: 'Troubleshooting', url: '/admin/diag.aspx', icon: <TroubleshootIcon fontSize="small" /> },
                    { title: 'Metadata Administration', url: '/admin/p_admin.aspx', icon: <AdminPanelSettingsIcon fontSize="small" /> },
                  ]
                    .map(
                      (child) => (
                        <StyledLi key={child.title}>
                          <StyledButton onClick={() => handleNavigate(child.url, child.title)}>
                            {child.icon}
                            <Typography variant="inherit" noWrap>
                              {child.title}
                            </Typography>
                          </StyledButton>
                        </StyledLi>
                      ),
                    )}
                </StyledUl>
              </StyledLi>
              <StyledLi>
                {`User: ${getUserName()}`}
                <StyledUl>
                  <StyledLi>
                    <StyledLink
                      to="/if/user_profile.aspx"
                      onClick={toggleMenu}
                    >
                      <PersonIcon fontSize="small" sx={{ color: 'white' }} />
                      <Typography variant="inherit" noWrap>Profile</Typography>
                    </StyledLink>
                  </StyledLi>
                  {userProfile.canImpersonate && (
                    <StyledLi>
                      <StyledLink
                        to="/if/admin/impersonate.aspx?notabs=1"
                        onClick={toggleMenu}
                      >
                        <SupervisedUserCircleIcon fontSize="small" sx={{ color: 'white' }} />
                        <Typography variant="inherit" noWrap>Impersonate User</Typography>
                      </StyledLink>
                    </StyledLi>
                  )}
                  {workspace?.isOpenCase && (
                    <StyledLi>
                      <StyledLink
                        to={`/if/workspace.aspx?CLOSECASE=${workspace.id}`}
                        onClick={toggleMenu}
                      >
                        <WorkIcon fontSize="small" sx={{ color: 'white' }} />
                        <Typography variant="inherit" noWrap>Leave Case</Typography>
                      </StyledLink>
                    </StyledLi>
                  )}
                  <StyledLi>
                    <StyledButton
                      onClick={handleLogoff}
                    >
                      <LogoutIcon fontSize="small" sx={{ color: 'white' }} />
                      <Typography variant="inherit" noWrap>
                        Logout
                      </Typography>
                    </StyledButton>
                  </StyledLi>
                </StyledUl>
              </StyledLi>
            </StyledUl>
          </Box>
        )}
      </AppBar>
    </>
  );
}
