// Them
import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Route } from 'react-router';
import { Store } from 'redux';
import { Provider, connect } from 'react-redux';
import { History } from 'history';
import { useMediaQuery } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, RootState } from '@store/store';

// Us
import GlobalStateMonitor from '@components/data/GlobalStateMonitor';
import Layout from '@components/layout/Layout';
import LayoutMobile from '@components/layout/LayoutMobile';
import ContentFrame from '@components/layout/ContentFrame';
import NotFound from '@components/layout/NotFound';
import { reactPathTS } from '@components/utilities/Paths';
import theme from '@components/layout/Theme';
import '@css/App.css';
import ContentListWidgetStateProvider from '@components/layout/ContentListWidgetState';
import SystemInformationUI from '@pages/SystemInformation';
import Home from '@pages/Home';
import { FontLoadingContextWrapper } from '@components/layout/FontLoadingContext';
import PageTitle from '@components/utilities/PageTitle';
import UserProfile from '@models/UserProfile';
import { store as storeUserProfile } from '@store/userProfileSlice';
import NetworkMonitor, { DisableUX } from '@components/data/NetworkMonitor';
import LiveStats from '@pages/LiveStats';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    ...theme.typography.body1,
    '& .MuiButton-outlined': {
      borderColor: 'white',
      color: 'white',
    },
    '&:hover .MuiButton-outlined': {
      borderColor: 'white',
      color: 'white',
    },
  },
}));

const DevTools = React.lazy(() => import('@pages/DevTools'));

interface PropsDispatch {
  [key: string]: any;
}

interface OwnProps {
  store: Store<RootState>;
  history: History<unknown>;
  userProfile?: UserProfile;
}

type Props = PropsDispatch & OwnProps;

// READ BEFORE MODIFIYING THE SWITCH STATEMENT BELOW:
// Having multiple <Layout> elements can cause us to lose state.
// The showChrome param adds the header and navigation bar.
// If you add another showChrome param, it will not share the same state.
// Also, make sure the <Route> without a path is always last because it is a catch all <Route>
function App(props: Props) {
  const { store, history, userProfile } = props;
  const { dispatch } = store; // Do not use useAppDispatch(), it relies on Provider context component.
  const LayoutComp = useMediaQuery(theme.breakpoints.down('md')) ? LayoutMobile : Layout;

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // Use React.lazy to prevent including the DevComponent in the production build.
  const DevComponent = () => (
    process.env.NODE_ENV !== 'production' ? (
      <PageTitle title="Development Tools">
        <LayoutComp showChrome>
          <Suspense fallback={<div>Loading...</div>}>
            <DevTools />
          </Suspense>
        </LayoutComp>
      </PageTitle>
    ) : null
  );

  const handleMessage = (event: { data: { bpChanged: undefined; }; }) => {
    if (event.data && event.data.bpChanged !== undefined) {
      (window as any).bpChanged = event.data.bpChanged;
    }
  };

  const onBeforeLift = () => {
    if (userProfile) {
      dispatch(storeUserProfile(userProfile));
    }
  };

  // *** BE CAREFUL moving around various providers and GlobalStateMonitor. ***
  // Generally speaking, many of these items should remain "mounted" (in the React virtual DOM) for the life of the app.
  // Otherwise, we can lose state that's meant to persist until the user is done with their PD session. Mounted means
  // it's not placed in the React DOM such that sometimes it's skipped, which will happen for anything under a <Route>
  // node.
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              maxSnack={6}
              Components={{
                networkMonitor: NetworkMonitor,
                default: StyledMaterialDesignContent,
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
                warning: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
              }}
            >
              <GlobalStateMonitor ignoredPaths={[`${reactPathTS}sysinfo`, `${reactPathTS}livestats`]} />
              <DisableUX />
              <FontLoadingContextWrapper>
                <ContentListWidgetStateProvider>
                  <Router basename={reactPathTS}>
                    <Switch>
                      <Route path="/sysinfo" exact>
                        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={6}>
                          <PageTitle title="Process Director System Information Page">
                            <main>
                              <SystemInformationUI />
                            </main>
                          </PageTitle>
                        </SnackbarProvider>
                      </Route>
                      <Route path="/livestats" exact>
                        <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={6}>
                          <PageTitle title="Process Director Live Stats">
                            <main>
                              <LiveStats />
                            </main>
                          </PageTitle>
                        </SnackbarProvider>
                      </Route>
                      <Route path="/" exact>
                        <LayoutComp />
                        <header className="App-header">
                          <p>
                            Loading…
                            <img src={`${reactPathTS}bp-logix-logo.svg`} alt="" />
                          </p>
                        </header>
                      </Route>
                      <Route path="/home">
                        <PageTitle title="Home">
                          <LayoutComp />
                          <Home />
                        </PageTitle>
                      </Route>
                      <Route path="/dev">
                        <DevComponent />
                      </Route>
                      <Route path="/if/:destUrl([^?]+)">
                        <LayoutComp />
                        <ContentFrame />
                      </Route>
                      <Route>
                        <PageTitle title="Not Found">
                          <LayoutComp />
                          <NotFound />
                        </PageTitle>
                      </Route>
                    </Switch>
                  </Router>
                </ContentListWidgetStateProvider>
              </FontLoadingContextWrapper>
            </SnackbarProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

const mapStateToProps = () => ({});

export default connect<{}, PropsDispatch, OwnProps, RootState>(mapStateToProps)(App);
