/* eslint-disable no-bitwise */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CaseCloser from '@/components/layout/CaseCloser';

// Us
import Workspace from '@models/Workspace';
import { setActiveWorkspaceAsync } from '@store/workspacesSlice';
import { CustomTheme } from '@components/layout/Theme';
import { navContent } from '@components/utilities/Navigation';

const StyledToggleButton = styled(ToggleButton)(({ theme }: { theme: CustomTheme }) => ({
  '&': {
    ...theme.typography.button,
    textTransform: 'none',
    fontWeight: 'bold',
    height: '30px',
    alignSelf: 'center',
    color: theme.palette.common.black,
    marginRight: theme.spacing(0.5),
    borderWidth: 0,
  },
  '&.Mui-selected': {
    color: 'black',
    backgroundColor: theme.palette.action.hoverInverse,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hoverInverse,
  },
}));

interface OwnProps {
  cases?: Workspace[];
  onClose?(event: object): any;
}

export default function Cases(props: React.PropsWithChildren<OwnProps>) {
  const { cases } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCaseClose = () => { setAnchorEl(null); };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const handleSwitchCase = async (workspace: Workspace, openInNewTab: boolean = false) => {
    setActiveWorkspaceAsync(dispatch, workspace.id);
    if (openInNewTab) {
      window.open(workspace.url, '_blank');
    } else {
      navContent(history, workspace.url);
    }
    setAnchorEl(null);
  };

  return (
    <Box sx={{ whiteSpace: 'nowrap' }}>
      <StyledToggleButton value="depressed" selected={!!anchorEl} onClick={handleClick}>
        Open Cases
        {anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </StyledToggleButton>
      <Menu
        id="CasesMenu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        {cases && cases.length && cases
          .slice()
          .sort((lhs, rhs) => lhs.order - rhs.order)
          .map(
            (child) => (
              <MenuItem key={child.id} sx={{ paddingLeft: '4px' }}>
                <ListItemButton onClick={() => handleSwitchCase(child)} onContextMenu={() => handleSwitchCase(child, true)}>
                  <ListItemText primary={child.name} />
                </ListItemButton>
                <CaseCloser onClose={handleCaseClose} activeCaseId={child?.id} />
              </MenuItem>
            ),
          )}
      </Menu>
    </Box>
  );
}
